import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable, of, throwError } from 'rxjs';

import { AuthService } from '@app/_auth/auth.service';

import { ToastService } from '../_modules/toast/toast.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private readonly authService: AuthService,
    private readonly toastService: ToastService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // TODO: być może w tym miejscu powinna być również obsługa 500 (app/error)

        if (request.method === 'GET' && error.status === 404 && request.responseType !== 'blob') {
          this.router.navigateByUrl('app/not-found');
          return of(null);
        }

        if (error.status === 401) {
          this.authService.signOut();
          this.toastService.show('Your session expired', {
            header: 'Please log again',
            type: 'danger',
            delay: 9000
          });
        }

        return throwError(() => error);
      })
    );
  }
}
