import { HttpErrorResponse } from '@angular/common/http';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { uniqBy, isNil } from 'lodash-es';
import { EMPTY, Subject } from 'rxjs';
import { catchError, combineLatest, map, Observable, switchMap, tap, withLatestFrom, pairwise, startWith } from 'rxjs';
import { filter, finalize } from 'rxjs/operators';

import { getFirstSection } from '@app/domain/category-template';
import { ImageSize } from '@app/domain/image';
import { or } from '@app/shared/_helpers/fp';
import { ToastService } from '@app/shared/_modules/toast/toast.service';
import { FileApiService } from '@app/shared/_services/file';
import { updateOne } from '@shared/_helpers/fp/update_one';
import { UpdateArea } from '@shared/_models';
import {
  DevelopmentItem,
  ImageDto,
  Image,
  ImageMetaUpdateData,
  NeighborsDto,
  SectionType,
  PaginationDto,
  LinkedFileDto,
  DevelopmentType,
  DeleteMultipleResponseDto,
  CategoryDto,
  DevelopmentItemFileDto
} from '@shared/_models';
import { FileService } from '@shared/_modules/file/file.service';
import { notifyAboutError } from '@shared/_root-store/app-store/app.actions';
import { loadImageContent, loadImagesContent } from '@shared/_root-store/images-store/images.actions';
import { selectImagesState } from '@shared/_root-store/images-store/images.selectors';
import { selectSelectedProject } from '@shared/_root-store/projects-store/projects.selectors';
import { CategoriesApiService } from '@shared/_services/category/categories-api.service';
import { ImageApiService } from '@shared/_services/image/image-api.service';

export interface CommonDevelopmentItemState<T> {
  readonly entity: T;
  readonly imagesSectionId: string;
  readonly parameterSectionId: string;
  readonly neighborData: NeighborsDto;
  readonly pendingArea: UpdateArea;
  readonly categoriesWithFiles: CategoryDto[];
  readonly filesPage: {
    pagination: PaginationDto;
    data: LinkedFileDto[];
    selected: LinkedFileDto[];
    pending: boolean;
  };
}

export function getDefaultCommonDevelopmentItemState<T>(): CommonDevelopmentItemState<T> {
  return {
    entity: null,
    imagesSectionId: null,
    parameterSectionId: null,
    neighborData: null,
    pendingArea: null,
    categoriesWithFiles: null,
    filesPage: {
      pagination: { limit: 20, offset: 0, total_records: 0 },
      data: null,
      selected: [],
      pending: false
    }
  };
}

export class CommonDevelopmentItemComponentStore<
  T extends CommonDevelopmentItemState<TEntity>,
  TEntity extends DevelopmentItem
> extends ComponentStore<T> {
  readonly shareFiles$ = new Subject<void>();

  constructor(
    defaultState: T,
    protected imageApiService: ImageApiService,
    protected fileApiService: FileApiService,
    protected fileService: FileService,
    protected store: Store,
    protected toastService: ToastService,
    private categoriesApiService: CategoriesApiService
  ) {
    super(defaultState);
  }

  readonly entity$ = this.select(({ entity }) => entity);
  readonly lastTwoEntities$ = this.entity$.pipe(startWith(null), pairwise());
  readonly neighborData$ = this.select(state => state.neighborData);
  readonly previousItemId$ = this.neighborData$.pipe(map(data => data?.previous_id));
  readonly nextItemId$ = this.neighborData$.pipe(map(data => data?.next_id));
  readonly pendingArea$ = this.select(state => state.pendingArea);
  readonly pending$ = this.select(state => !!state.pendingArea);
  readonly imagesSectionId$ = this.select(state => state.imagesSectionId);
  readonly parameterSectionId$ = this.select(state => state.parameterSectionId);
  readonly images$: Observable<Image[]> = combineLatest([this.entity$, this.imagesSectionId$, this.store.select(selectImagesState)]).pipe(
    map(([entity, sectionId, imagesState]) => {
      if (!entity || !sectionId) return [];

      const isFirstSection = getFirstSection(entity.template, SectionType.IMAGES).id === sectionId;
      const isFromCurrentSection = (image: ImageDto) => image.section_id === sectionId;
      const isAutoGeneratedFromCurrentSection = (image: ImageDto) => !image.section_id && isFirstSection;
      const imagePredicate = or(isFromCurrentSection, isAutoGeneratedFromCurrentSection);
      const imagesInCurrentSection = entity.images.filter(imagePredicate);

      return imagesInCurrentSection
        .map(imageMeta => ({
          ...imageMeta,
          content: imagesState.images[ImageSize.LARGE].entities[imageMeta.id]
        }))
        .sort((a, _) => (a.id === entity.main_image_id ? -1 : 0));
    })
  );
  readonly #developmentType$: Observable<DevelopmentType> = this.entity$.pipe(map(entity => entity?.template?.category?.development_type));
  readonly project$ = this.store.select(selectSelectedProject);

  // FILES PAGE SELECTORS
  readonly files$ = this.select(state => state.filesPage.data);
  readonly filesPagination$ = this.select(state => state.filesPage.pagination);
  readonly filesSelected$ = this.select(state => state.filesPage.selected);
  readonly filesPending$ = this.select(state => !state.filesPage.data || state.filesPage.pending);
  readonly categoriesWithFiles$ = this.select(state => state.categoriesWithFiles ?? []);

  readonly setEntity = this.updater((state, entity: DevelopmentItem) => ({ ...state, entity }));
  readonly setAddShare = this.updater((state, share: DevelopmentItemFileDto) => ({
    ...state,
    entity: { ...state.entity, files: [share, ...state.entity.files] }
  }));
  readonly setImages = this.updater((state, images: ImageDto[]) => ({ ...state, entity: { ...state.entity, images } }));
  readonly setAddImage = this.updater((state, image: ImageDto) => ({
    ...state,
    entity: { ...state.entity, images: [...state.entity.images, image] }
  }));
  readonly setImagesWithout = this.updater((state, imageId: string) => ({
    ...state,
    entity: { ...state.entity, images: state.entity?.images?.filter(image => image.id !== imageId) }
  }));
  readonly setFilesWithout = this.updater((state, fileId: string) => ({
    ...state,
    entity: { ...state.entity, files: state.entity?.files?.filter(share => share.file.id !== fileId) }
  }));
  readonly setNeighborData = this.updater((state, neighborData: NeighborsDto) => ({ ...state, neighborData }));
  readonly setPendingArea = this.updater((state, pendingArea: UpdateArea) => ({ ...state, pendingArea }));
  readonly setImageAsMain = this.updater((state: T, imageId: string) => {
    const entity = state.entity;

    return {
      ...state,
      entity: {
        ...entity,
        main_image_id: imageId
      }
    };
  });
  readonly setImagesSectionId = this.updater((state, imagesSectionId: string) => ({ ...state, imagesSectionId }));
  readonly setParameterSectionId = this.updater((state, parameterSectionId: string) => ({ ...state, parameterSectionId }));

  // FILES PAGE SETTERS
  readonly setFiles = this.updater((state, data: LinkedFileDto[]) => ({
    ...state,
    filesPage: { ...state.filesPage, data }
  }));
  readonly setFilesPagination = this.updater((state, pagination: PaginationDto) => ({
    ...state,
    filesPage: { ...state.filesPage, pagination }
  }));
  readonly setFilesSelected = this.updater((state, selected: LinkedFileDto[]) => ({
    ...state,
    filesPage: { ...state.filesPage, selected }
  }));
  readonly setFilesPending = this.updater((state, pending: boolean) => ({
    ...state,
    filesPage: { ...state.filesPage, pending }
  }));
  readonly setCategoriesWithFiles = this.updater((state, categoriesWithFiles: CategoryDto[]) => ({ ...state, categoriesWithFiles }));

  readonly updateImageMeta = this.effect(
    (data$: Observable<ImageMetaUpdateData & { onSuccess?: () => void; onFail?: (errorResponse: HttpErrorResponse) => void }>) => {
      return data$.pipe(
        switchMap(data => {
          return this.imageApiService.updateMeta({ imageId: data.imageId, payload: data.payload }).pipe(
            withLatestFrom(this.images$),
            tap(([updatedImageMeta, images]) => {
              data.onSuccess?.();
              const updateImage = updateOne((image: ImageDto) => image.id === updatedImageMeta.id, updatedImageMeta);
              this.setImages(updateImage(images));
            }),
            catchError((errorResponse: HttpErrorResponse) => {
              data.onFail?.(errorResponse);

              return EMPTY;
            })
          );
        })
      );
    }
  );

  readonly loadImage = this.effect((imageMeta$: Observable<ImageDto>) =>
    imageMeta$.pipe(tap((imageMeta: ImageDto) => this.store.dispatch(loadImageContent({ imageMeta, size: ImageSize.LARGE }))))
  );

  readonly loadImages = this.effect((data$: Observable<DevelopmentItem>) =>
    data$.pipe(
      tap((developmentItem: DevelopmentItem) =>
        this.store.dispatch(loadImagesContent({ imagesMeta: developmentItem.images, size: ImageSize.LARGE }))
      )
    )
  );

  // FILES PAGE EFFECTS

  readonly loadFiles = this.effect((data$: Observable<void>) =>
    data$.pipe(
      tap(() => this.setFilesPending(true)),
      withLatestFrom(
        this.select(state => state.entity),
        this.filesPagination$,
        this.#developmentType$
      ),
      switchMap(([_, entity, pagination, development_type]) =>
        this.fileApiService
          .getList({
            object_id: entity?.id,
            development_type,
            limit: pagination?.limit,
            offset: pagination?.offset
          })
          .pipe(
            tap(filesData => {
              this.setFiles(filesData.data);
              this.setFilesPagination(filesData.pagination);
              this.setFilesPending(false);
            }),
            catchError(() => {
              this.setFilesPending(false);
              this.store.dispatch(notifyAboutError({ notification: { content: 'Loading files failed', header: 'ERROR' } }));

              return EMPTY;
            })
          )
      )
    )
  );

  readonly changeFilesPagination = this.effect((data$: Observable<{ pageIndex: number }>) =>
    data$.pipe(
      withLatestFrom(this.filesPagination$),
      tap(([data, pagination]: [{ pageIndex: number }, PaginationDto]) => {
        this.setFilesPagination({ ...pagination, offset: (data.pageIndex - 1) * 20 });
        this.loadFiles();
      })
    )
  );

  readonly selectFiles = this.effect((data$: Observable<string[]>) =>
    data$.pipe(
      withLatestFrom(this.filesSelected$, this.files$),
      tap(([toSelect, selected, files]: [string[], LinkedFileDto[], LinkedFileDto[]]) => {
        const toSelectFiles = files.filter(file => toSelect.includes(file.id));
        const filesSelected = uniqBy(selected.concat(toSelectFiles), file => file.id);
        this.setFilesSelected(filesSelected);
      })
    )
  );

  readonly unselectFiles = this.effect((data$: Observable<string[]>) =>
    data$.pipe(
      withLatestFrom(this.filesSelected$),
      tap(([toUnselect, selected]: [string[], LinkedFileDto[]]) => {
        this.setFilesSelected(selected.filter(file => !toUnselect.includes(file.id)));
      })
    )
  );

  readonly deleteMultipleFiles = this.effect((data$: Observable<{ fileIds: string[]; onFinalize?: () => void }>) => {
    return data$.pipe(
      tap(() => this.setPendingArea(UpdateArea.FILES)),
      switchMap(({ fileIds, onFinalize }) =>
        this.fileApiService.deleteMultiple(fileIds).pipe(
          withLatestFrom(this.entity$),
          tap(([response, entity]: [DeleteMultipleResponseDto, DevelopmentItem]) => {
            const { deleted, not_deleted } = response;

            if (deleted.length > 0) {
              this.setEntity({ ...entity, files: entity.files.filter(share => !deleted.includes(share.file.id)) });
              this.loadFiles();
              this.toastService.show('Selected files removed successfully', {
                header: 'Files removed',
                type: 'success'
              });
            }

            if (not_deleted.length > 0) {
              this.toastService.show(
                'Some (or all) files were not deleted. This may be caused by another user trying to delete the same files at the same time.',
                {
                  header: 'Files not removed',
                  type: 'danger',
                  delay: 160000
                }
              );
            }

            onFinalize?.();
          }),
          finalize(() => this.setPendingArea(null))
        )
      )
    );
  });

  export = this.effect((data$: Observable<void>) =>
    data$.pipe(
      withLatestFrom(this.filesSelected$, this.entity$),
      switchMap(([_, selected, entity]: [void, LinkedFileDto[], DevelopmentItem]) =>
        this.fileApiService
          .getMultiple(
            selected.map(file => file.id),
            entity.id,
            entity.template.category.development_type
          )
          .pipe(
            tap((blob: Blob) => {
              const getMultipleFilesName = (): string => {
                const now = new Date();

                return `export_${now.getTime()}`;
              };
              const fileName = selected.length === 1 ? selected[0].name : getMultipleFilesName();
              this.fileService.saveFile(blob, fileName);
            })
          )
      )
    )
  );

  exportOne = this.effect((data$: Observable<string>) =>
    data$.pipe(
      withLatestFrom(this.entity$),
      switchMap(([fileId, entity]: [string, DevelopmentItem]) =>
        this.fileApiService.get(fileId).pipe(
          tap((blob: Blob) => {
            const fileName = entity.files.find(share => share.file.id === fileId).name;
            this.fileService.saveFile(blob, fileName);
          })
        )
      )
    )
  );

  loadCategoriesWithFiles = this.effect((data$: Observable<void>) =>
    data$.pipe(
      withLatestFrom(this.select(state => state.categoriesWithFiles)),
      filter(([_, categoriesWithFiles]) => isNil(categoriesWithFiles)),
      switchMap(() =>
        this.categoriesApiService.getList({ include_files: true }).pipe(tap(categories => this.setCategoriesWithFiles(categories)))
      )
    )
  );

  protected updateShareNameInStore = this.effect((data$: Observable<{ fileId: string; shareName: string }>) =>
    data$.pipe(
      withLatestFrom(this.entity$, this.files$),
      tap(
        ([{ fileId, shareName }, entity, files]: [
          { fileId: string; shareName: string },
          entity: DevelopmentItem,
          files: LinkedFileDto[]
        ]) => {
          this.setEntity({
            ...entity,
            files: entity.files.map(share => (share.file.id === fileId ? { ...share, name: shareName } : share))
          });
          this.setFiles(files.map(share => (share.id === fileId ? { ...share, share_name: shareName } : share)));
        }
      )
    )
  );

  protected setSharesStateAfterRemoval = this.effect((data$: Observable<string[]>) =>
    data$.pipe(
      withLatestFrom(this.entity$, this.files$, this.filesPagination$),
      tap(([fileIds, entity, files, filesPagination]: [string[], DevelopmentItem, LinkedFileDto[], PaginationDto]) => {
        this.setEntity({
          ...entity,
          files: entity.files.filter(share => !fileIds.includes(share.file.id))
        });
        if (filesPagination.total_records > filesPagination.limit) {
          this.loadFiles();
        } else {
          this.setFiles(files.filter(share => !fileIds.includes(share.id)));
          this.setFilesPagination({ ...filesPagination, total_records: filesPagination.total_records - fileIds.length });
        }
      })
    )
  );
}
