<div [ngClass]="{ 'file-card-excel': uploadExcel, 'file-card-sharing d-flex': fileSharing, 'file-card--error': withError() }" class="file-card" data-testid="file-card">
  <div [ngClass]="fileSharing ? 'file-info-sharing' : 'd-flex'">
    <app-file-thumb
      *ngIf="!uploadExcel && (!fileSharing || !fileUploadInProgress)"
      [error]="withError()"
      [image]="this.fileCategory() === FileCategory.images"
      class="me-2"></app-file-thumb>
    <app-file-thumb-rotate
      *ngIf="uploadExcel || (fileSharing && fileUploadInProgress)"
      [fileAnalyzed]="excelAnalyzed && analyzeExcel"
      [fileFailed]="fileUploadFail"
      class="me-2"></app-file-thumb-rotate>
    <div [ngClass]="{ 'file-text-excel': uploadExcel || fileSharing }" class="d-flex flex-column overflow-hidden flex-fill">
      @if (!uploadExcel && !fileSharing && !isShare()) {
        <div class="file-card__progress-bar-container">
          <div
            class="file-card__progress-bar"
            [style.width]="failureMessage ? '100%' : fileUploadProgress + '%'"
            [ngClass]="{ 'file-card__progress-bar--success': fileId(), 'file-card__progress-bar--fail': failureMessage || (showShareInput() && control?.invalid) || fileUploadFailed() }"></div>
        </div>
      }
      @if (isShare()) {
        <div class="file-card__share-progress-bar">From shares browser</div>
      }

      <div class="file-card__sharing-label" *ngIf="fileSharing && !fileUploadInProgress && !fileUpdateComplete">File</div>
      <div class="file-card__sharing-label" *ngIf="fileSharing && fileUploadInProgress">File updating...</div>
      <div class="file-card__uploading" *ngIf="uploadExcel && !analyzeExcel && !fileUploadFail">Importing...</div>
      <div class="file-card__updated" *ngIf="fileSharing && fileUpdateComplete">File updated succesfully</div>
      <div class="file-card__analyzing" *ngIf="uploadExcel && analyzeExcel && !excelAnalyzed && !fileUploadFail">Analyzing...</div>
      <div *ngIf="uploadExcel && analyzeExcel && excelAnalyzed && !fileUploadFail">Analyzed</div>
      <div class="file-card__uploading-failed" *ngIf="uploadExcel && fileUploadFail">Import failed</div>
      <div class="overflow-ellipsis file-card__file-name">{{ fileNameDisplay() | fileWithoutExtension }}</div>
      <div class="d-flex justify-content-between file-card__meta-data-container">
        <div class="file-card__file-size">{{ fileSize | formatFileSize }}</div>
        <div class="file-card__file-extension" data-testid="file-extension">
          {{ uploadExcel ? getFileExtention(fileName()) : (fileExtension | mimeSubtype) }}
        </div>
      </div>

      @if (fileSharing) {
        <input type="file" class="d-none lg-files-modal__input" id="fileInput" (change)="onFileSelected($event)" />
        <div class="file-more-actions">
          <app-more-actions
          [actions]="sourceFileActions"
          buttonLabel="ACTIONS"
          class="me-1 related-item__actions"
        ></app-more-actions>
        </div>
      }

      @if (!fileSharing) {
        <div>
          @if (failureMessage && !fileUploadFailed()) {
            <span class="file-card__failure-message">{{ failureMessage }}</span>
          }
          <button class="file-card__action-btn file-card__cancel-btn" (click)="onCancelBtnClick()" *ngIf="!fileId() && !failureMessage">
            {{ uploadExcel && analyzeExcel && excelAnalyzed && !fileUploadFail ? 'REMOVE' : 'CANCEL' }}
          </button>
          <button
            data-testid="img-delete-button"
            class="file-card__action-btn file-card__remove-btn"
            [disabled]="disabled()"
            (click)="onRemoveBtnClick()"
            *ngIf="fileId() && !failureMessage || fileUploadFail">
            REMOVE
          </button>
        </div>
      }
    </div>
  </div>

  @if (fileCategory() === FileCategory.files && name() && !showShareInput() && !fileUploadFailed()) {
    <div class="file-card__share-name">
      <app-icon width="0.521rem" height="0.521rem" fileName="share.svg" [color]="IconColors.darkGrey"></app-icon>
      <span>{{ name() }}</span>
    </div>
  }

  @if (showShareInput()) {
    <div class="file-card__share-name-edit">
      <div class="file-card__share-name-edit__header">{{ editNameInfo().header }}</div>
      <app-input-with-validation [control]="control" [config]="editNameInfo().config">
        <app-input-with-clear
          [formControl]="control"
          [hasError]="control.touched && (control.invalid)"
          [icon]="{ name: editNameInfo().placeholderIcon, color: IconColors.darkGrey, width: '0.75rem', height: '0.75rem' }"
          [placeholder]="editNameInfo().placeholder"
          data-testid="share-name-input">
        </app-input-with-clear>
      </app-input-with-validation>
      <app-button
        (click)="create.emit()"
        [disabled]="control.invalid || disabled() "
        [ngClass]="editNameInfo().buttonClass"
        data-testid="accept-modal-button">
        {{ editNameInfo().buttonLabel }}
      </app-button>
    </div>
  }

  @if (fileUploadFailed()) {
    <div class="file-card__upload-failed">Upload failed</div>
  }
</div>
