<div [ngClass]="rootClass()">
  <div class="lg-files-modal__main-panel">
    <app-modal-header-left
      [header]="header().top"
      [imgSrc]="headerIcon()"
      [whiteIconFilter]="true"
      [itemName]="header().middle"
      [developmentType]="developmentType()"
      [categoryName]="header().bottom"
      [hideCloseIcon]="true">
    </app-modal-header-left>
    <div
      data-testid="file-drop-area"
      class="lg-files-modal__drop-area"
      (drop)="dropHandler($event)"
      (dragover)="handleDragEvent($event)"
      (dragenter)="handleDragEvent($event)"
      (dragleave)="handleDragEvent($event)">
      <input
        #fileInputElement
        data-testid="add-file-input"
        type="file"
        class="d-none lg-files-modal__input"
        id="fileInput"
        multiple
        [attr.accept]="fileCategory() | fileUploadLimitAcceptedExtensions"
        (change)="onFileSelected($event)" />
      <div class="lg-files-modal__drop-text">
        <img src="../../../../../assets/icons/file_upload_icon.svg" alt="file upload icon" />
        <div class="mt-2">
          <span data-testid="modal-upload-info" class="lg-files-modal__upload-info">
            To add {{ fileCategory() }}
            <br />
            @if (fileCategory() === FileCategory.files) {
              drop it here,
              <ng-container *ngTemplateOutlet="browseBtnLabel"></ng-container>
              , or ...
            } @else {
              drop it here or
              <ng-container *ngTemplateOutlet="browseBtnLabel"></ng-container>
              to upload
            }
            <ng-template #browseBtnLabel>
              <label data-testid="browse-files-button" class="lg-files-modal__browse-btn" for="fileInput">browse</label>
            </ng-template>
          </span>
        </div>
        <div class="lg-files-modal__upload-limit">max 10 files up to 50 mb</div>
      </div>
    </div>

    @if (fileCategory() === FileCategory.files) {
      <app-files-upload-share-info [project]="selectedProject()" (shareFiles)="shareFiles.emit()"></app-files-upload-share-info>
    }
  </div>
  @if (fileUploadStatuses().length) {
    <div class="lg-files-modal__uploading-panel">
      <h2>{{ uploadingPanelHeader() }}</h2>
      <app-upload-cards
        [fileUploadStatuses]="fileUploadStatuses()"
        [anyUploadPending]="anyUploadPending()"
        [files]="files()"
        [fileCategory]="fileCategory()"
        [developmentType]="developmentType()"
        (cancel)="onUploadCancel($event)"
        (succeeded)="onUploadSuccess($event)"
        (inProgress)="onUploadInProgress($event)"
        (failed)="onUploadFail($event)"
        (remove)="onFileRemove($event)"
        (shareNameChanged)="onShareNameChanged($event)"
        (create)="onCreate($event)">
      </app-upload-cards>
    </div>
  }
  <button
    [disabled]="anyUploadPending() || pending()"
    [disableTooltip]="!anyUploadPending() && !pending()"
    ngbTooltip="Upload in progress"
    type="button"
    class="lg-files-modal__btn-close"
    aria-label="Close"
    (click)="onClose()"
    data-testid="close-modal-button"
  ></button>
</div>
