import { AsyncPipe, NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Inject, signal, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, EMPTY, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { CONTACT_ADMINISTRATOR } from '@app/_auth/constants';
import { addErrorToForm } from '@app/domain/error-handling';
import { ButtonComponent } from '@app/shared/_components/button/button.component';
import { GenericModalComponent } from '@app/shared/_components/generic-modal/generic-modal.component';
import { IconColors } from '@app/shared/_components/icon/utils/icon-colors';
import { AppSettings } from '@app/shared/_configuration';
import { EmailWithDomainValidator } from '@app/shared/_validators/email-with-domain.validator';
import { NameValidator, PasswordConditionsValidator } from '@shared/_validators';

import { AuthRoutingPath } from '../../auth-routing-path';
import { AuthService } from '../../auth.service';
import { AuthSignUpRequest, AuthSignUpResponse } from '../../interface';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { UserAgreementComponent } from './user-agreement/user-agreement.component';
import { IconComponent } from '../../../shared/_components/icon/components/icon/icon.component';
import { PasswordStrengthMeterComponent } from '../../password-strength-meter/password-strength-meter.component';

enum SIGNUP_FORM_KEYS {
  NAME = 'name',
  SURNAME = 'surname',
  EMAIL = 'email',
  PASSWORD = 'password'
}

@Component({
  selector: 'app-signup',
  imports: [
    AsyncPipe,
    NgClass,
    NgIf,
    NgTemplateOutlet,
    ReactiveFormsModule,
    RouterLink,
    GenericModalComponent,
    PasswordStrengthMeterComponent,
    PrivacyPolicyComponent,
    UserAgreementComponent,
    ButtonComponent,
    IconComponent
  ],
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class SignupComponent {
  @ViewChild('userAgreementModal', { static: false }) userAgreementModal: ElementRef;
  @ViewChild('privacyPolicyModal', { static: false }) privacyPolicyModal: ElementRef;

  formSubmitted$ = new BehaviorSubject(false);
  signedUpUser = signal<AuthSignUpResponse>(null);

  readonly signInRoutingPath = ['/', AuthRoutingPath.auth, AuthRoutingPath.signIn];
  readonly CONTACT_ADMINISTRATOR = CONTACT_ADMINISTRATOR;
  readonly SIGNUP_FORM_KEYS = SIGNUP_FORM_KEYS;
  readonly IconColors = IconColors;

  focusFirstName = signal(false);
  focusLastName = signal(false);
  focusEmail = signal(false);
  focusPassword = signal(false);

  signupForm = new FormGroup({
    [SIGNUP_FORM_KEYS.NAME]: new FormControl(null, [Validators.required, NameValidator]),
    [SIGNUP_FORM_KEYS.SURNAME]: new FormControl(null, [Validators.required, NameValidator]),
    [SIGNUP_FORM_KEYS.EMAIL]: new FormControl(null, [Validators.required, EmailWithDomainValidator]),
    [SIGNUP_FORM_KEYS.PASSWORD]: new FormControl(null, [Validators.required, PasswordConditionsValidator])
  });
  modalSetting = {
    ...this.settings.MODAL_DEFAULT_CONFIG,
    fullscreen: true
  };

  hasPasswordConditionsError$ = combineLatest([this.signupForm.valueChanges, this.signupForm.statusChanges, this.formSubmitted$]).pipe(
    map(([, , formSubmitted]) => formSubmitted && this.signupForm.controls.password.hasError('meetsConditions'))
  );

  agreementAccepted = signal(false);

  constructor(
    @Inject(AppSettings) private readonly settings: AppSettings,
    private readonly authService: AuthService,
    private readonly ngbModal: NgbModal
  ) {}

  signUp($event: Event): void {
    $event.preventDefault();
    $event.stopPropagation();

    const requestData = this.signupForm.value as AuthSignUpRequest;

    this.formSubmitted$.next(true);

    if (this.signupForm.valid && requestData) {
      this.authService
        .signUp(requestData)
        .pipe(
          catchError(error => {
            if ([400, 404, 422, 409].includes(error.status)) {
              addErrorToForm(error, this.signupForm);
              return EMPTY;
            }
            throwError(() => error);
          })
        )
        .subscribe(response => {
          this.signedUpUser.set(response);
        });
    }
  }

  showUserAgreement($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();

    this.ngbModal.open(this.userAgreementModal, this.modalSetting);
  }

  showPrivacyPolicy($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();

    this.ngbModal.open(this.privacyPolicyModal, this.modalSetting);
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  toggleAgreement($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();

    this.agreementAccepted.set(!this.agreementAccepted());
  }
}
