import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { filter, catchError, concatMap, finalize, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { getGoToExperimentArgs } from '@app/_research_objects/research-object/utils/get-go-to-experiment-args';
import { comparePositions } from '@app/domain/shared/compare-positions';
import {
  ImageDto,
  NeighborsDto,
  ResearchObject,
  ResearchObjectUpdateDto,
  UpdateArea,
  FieldValueDto,
  SectionType,
  DevelopmentType,
  DevelopmentItemFile
} from '@shared/_models';
import { FileService } from '@shared/_modules/file/file.service';
import { ToastService } from '@shared/_modules/toast/toast.service';
import { notifyAboutError } from '@shared/_root-store/app-store/app.actions';
import {
  CommonDevelopmentItemComponentStore,
  CommonDevelopmentItemState,
  getDefaultCommonDevelopmentItemState
} from '@shared/_root-store/common-development-item-store/common-development-item-component-store';
import { selectShowExperiments } from '@shared/_root-store/projects-store/projects.selectors';
import { selectProjectProxy } from '@shared/_root-store/projects-store/projects.selectors';
import { selectFromRoot } from '@shared/_root-store/router-store/selectors';
import { CategoriesApiService } from '@shared/_services/category/categories-api.service';
import { DevelopmentItemService } from '@shared/_services/development-item.service';
import { FileApiService } from '@shared/_services/file/file-api.service';
import { ImageApiService } from '@shared/_services/image/image-api.service';
import { NavigationService } from '@shared/_services/navigation.service';
import { ResearchObjectsApiService } from '@shared/_services/research-object';
import { ResearchObjectParamsService } from '@shared/_services/research-object/research-object-params.service';
import { toResearchObject } from '@shared/dto-adapters/research-object';

import { selectCategoriesDictionary } from '../categories-store/categories.selectors';
import { selectTemplatesDictionary } from '../category-templates-store/category-templates.selectors';

export interface ResearchObjectState extends CommonDevelopmentItemState<ResearchObject> {
  readonly researchObjectSectionUpdating: boolean;
}

export const defaultResearchObjectState: ResearchObjectState = {
  ...getDefaultCommonDevelopmentItemState(),
  researchObjectSectionUpdating: false
};

@Injectable()
export class ResearchObjectComponentStore extends CommonDevelopmentItemComponentStore<ResearchObjectState, ResearchObject> {
  readonly #getGoToExperimentArgs: (experimentId: string) => Observable<[string, string, string, string, boolean]> =
    getGoToExperimentArgs();
  readonly projectProxy$ = this.store.select(selectProjectProxy);
  readonly showExperiments$ = this.store.select(selectShowExperiments);

  DevelopmentType = DevelopmentType;

  constructor(
    readonly researchObjectsApiService: ResearchObjectsApiService,
    private readonly developmentItemService: DevelopmentItemService,
    private readonly researchObjectParamsService: ResearchObjectParamsService,
    private readonly router: Router,
    private readonly navigationService: NavigationService,
    imageApiService: ImageApiService,
    fileApiService: FileApiService,
    fileService: FileService,
    store: Store,
    toastService: ToastService,
    categoriesApiService: CategoriesApiService
  ) {
    super(defaultResearchObjectState, imageApiService, fileApiService, fileService, store, toastService, categoriesApiService);
  }
  readonly setResearchObjectSectionUpdating = this.updater((state, researchObjectSectionUpdating: boolean) => ({
    ...state,
    researchObjectSectionUpdating
  }));

  resetState() {
    this.setState(defaultResearchObjectState);
  }

  readonly deleteImage = this.effect((data$: Observable<{ imageId: string }>) => {
    return data$.pipe(
      switchMap(data =>
        this.imageApiService.delete(data.imageId).pipe(
          tap(() => this.detachImage(data.imageId)),
          tap(() => {
            this.toastService.show('Selected image removed successfully', {
              header: 'Image removed',
              type: 'success'
            });
          })
        )
      )
    );
  });

  readonly loadResearchObject = this.effect((researchObjectId$: Observable<{ researchObjectId: string }>) => {
    return researchObjectId$.pipe(
      tap(() => this.setPendingArea(UpdateArea.RESEARCH_OBJECT)),
      switchMap(({ researchObjectId }) => this.researchObjectsApiService.get(researchObjectId)),
      withLatestFrom(this.store.select(selectCategoriesDictionary), this.store.select(selectTemplatesDictionary)),
      map(toResearchObject),
      tap((researchObject: ResearchObject) => {
        this.setEntity(researchObject);
        this.setPendingArea(null);
        this.loadImages(researchObject);
        this.loadFiles();
      }),
      switchMap((ro: ResearchObject) => this.#fetchNeighborsData(ro)),
      tap(neighborsData => this.setNeighborData(neighborsData)),
      withLatestFrom(this.lastTwoEntities$),
      tap(([_, [previousEntity, currentEntity]]) => this.#setSectionsIds(previousEntity, currentEntity)),
      catchError(errorResponse => {
        if (errorResponse.status === 422) {
          this.router.navigateByUrl('app/not-found');

          return EMPTY;
        }

        return of(notifyAboutError({ errorResponse }));
      })
    );
  });

  readonly reloadResearchObject = this.effect(trigger$ =>
    trigger$.pipe(
      tap(() => this.setPendingArea(UpdateArea.RESEARCH_OBJECT)),
      withLatestFrom(this.entity$),
      switchMap(([_, researchObject]) => this.researchObjectsApiService.get(researchObject.id)),
      withLatestFrom(this.store.select(selectCategoriesDictionary), this.store.select(selectTemplatesDictionary)),
      map(toResearchObject),
      tap((data: ResearchObject) => {
        this.loadFiles();
        this.setEntity(data);
        this.setPendingArea(null);
      })
    )
  );

  readonly updateResearchObject = this.effect(
    (
      paramsAndData$: Observable<{
        data: Partial<ResearchObjectUpdateDto>;
        area: UpdateArea;
        ommitToast?: boolean;
        onSuccess?: () => void;
        onFail?: (errorResponse: HttpErrorResponse) => void;
      }>
    ) => {
      return paramsAndData$.pipe(
        withLatestFrom(this.entity$),
        tap(() => this.setPendingArea(UpdateArea.RESEARCH_OBJECT)),
        switchMap(([{ data, area, ommitToast, onSuccess, onFail }, researchObject]) =>
          this.#updateResearchObject(researchObject.id, data, area).pipe(
            tap(researchObject => {
              onSuccess?.();

              if (area === UpdateArea.IMAGES) {
                this.loadImages(researchObject);
              }

              if (ommitToast) return;

              this.toastService.show('Saved changes successfully', {
                header: 'Saved changes',
                type: 'success'
              });
            }),
            catchError(errorResponse => {
              onFail?.(errorResponse);

              return of(notifyAboutError({ errorResponse }));
            }),
            finalize(() => this.setPendingArea(null))
          )
        )
      );
    }
  );

  readonly addShare = this.effect((data$: Observable<DevelopmentItemFile>) => {
    return data$.pipe(
      withLatestFrom(this.entity$),
      tap(() => this.setPendingArea(UpdateArea.FILES)),
      concatMap(([share, entity]) =>
        this.researchObjectsApiService.addShare(entity.id, { name: share.name, file_id: share.file.id }).pipe(
          tap(share => this.setAddShare(share)),
          catchError(() => {
            /* should indicate race condition uniq share name error,
             * so we ask for up to date list of shares for FE validation */
            this.reloadResearchObject();

            return EMPTY;
          }),
          finalize(() => this.setPendingArea(null))
        )
      )
    );
  });

  readonly updateParameters = this.effect((data$: Observable<FieldValueDto[]>) => {
    return data$.pipe(
      tap(() => this.setPendingArea(UpdateArea.PARAMETERS)),
      withLatestFrom(this.entity$),
      switchMap(([field_values, researchObject]) =>
        this.#updateResearchObject(researchObject.id, { field_values }, UpdateArea.PARAMETERS).pipe(
          tap(() => this.loadResearchObject({ researchObjectId: researchObject.id })),
          catchError(errorResponse => of(notifyAboutError({ errorResponse }))),
          finalize(() => this.setPendingArea(null))
        )
      )
    );
  });

  readonly goToExperiment = this.effect((data$: Observable<string>) => {
    return data$.pipe(
      switchMap(this.#getGoToExperimentArgs),
      tap((goToExperimentArgs: [string, string, string, string, boolean]) =>
        this.navigationService.goToExperimentInfo(...goToExperimentArgs)
      )
    );
  });

  readonly updateShare = this.effect((data$: Observable<{ fileId: string; shareName: string }>) => {
    return data$.pipe(
      tap(() => this.setPendingArea(UpdateArea.FILES)),
      withLatestFrom(this.entity$),
      switchMap(([data, researchObject]) =>
        this.researchObjectsApiService.updateShare(researchObject.id, { file_id: data.fileId, name: data.shareName }).pipe(
          tap(() => this.updateShareNameInStore({ fileId: data.fileId, shareName: data.shareName })),
          catchError(errorResponse => of(notifyAboutError({ errorResponse }))),
          finalize(() => this.setPendingArea(null))
        )
      )
    );
  });

  readonly removeShares = this.effect((data$: Observable<string[]>) => {
    return data$.pipe(
      tap(() => this.setPendingArea(UpdateArea.FILES)),
      withLatestFrom(this.entity$),
      switchMap(([shareIds, researchObject]) =>
        this.researchObjectsApiService.removeShares(researchObject.id, shareIds).pipe(
          tap(() => {
            this.setSharesStateAfterRemoval(shareIds);
            this.unselectFiles(shareIds);
          }),
          catchError(errorResponse => of(notifyAboutError({ errorResponse }))),
          finalize(() => this.setPendingArea(null))
        )
      )
    );
  });

  readonly addImage = this.effect((data$: Observable<{ payload: DevelopmentItemFile; isMain?: boolean }>) => {
    return data$.pipe(
      withLatestFrom(this.entity$, this.imagesSectionId$),
      concatMap(([{ payload, isMain }, entity, imagesSectionId]) => {
        const renamed = payload.name !== payload.file.name;

        return (renamed ? this.imageApiService.updateMeta({ imageId: payload.file.id, payload: { name: payload.name } }) : of(null)).pipe(
          switchMap(() =>
            this.researchObjectsApiService.addImage(entity.id, { image_id: payload.file.id, section_id: imagesSectionId }, isMain).pipe(
              tap((image: ImageDto) => {
                this.setAddImage(image);
                this.loadImage(image);
              }),
              catchError(() => {
                /* should indicate race condition uniq image name error,
                 * so we ask for up to date list of images for FE validation */
                this.reloadResearchObject();

                return EMPTY;
              })
            )
          )
        );
      })
    );
  });

  readonly detachImage = this.effect((data$: Observable<string>) => {
    return data$.pipe(
      withLatestFrom(this.entity$),
      switchMap(([imageId, researchObject]) => {
        const images_ids = researchObject.images
          .filter(image => image.id !== imageId)
          .map(image => ({ image_id: image.id, section_id: image.section_id }));

        return this.#updateResearchObject(
          researchObject.id,
          {
            images_ids,
            ...(images_ids.length === 0 ? { main_image_id: null } : {})
          },
          UpdateArea.IMAGES
        );
      })
    );
  });

  readonly ensureMainImageOnImagesAdded = this.effect((data$: Observable<void>) => {
    return data$.pipe(
      withLatestFrom(this.entity$),
      filter(([, researchObject]: [void, ResearchObject]) => !researchObject.main_image_id && !!researchObject.images.length),
      switchMap(([, researchObject]: [void, ResearchObject]) =>
        this.#updateResearchObject(researchObject.id, { main_image_id: researchObject.images[0].id }, UpdateArea.IMAGES)
      )
    );
  });

  #updateResearchObject(id: string, data: Partial<ResearchObjectUpdateDto>, sectionName: UpdateArea): Observable<ResearchObject> {
    this.researchObjectsApiService.setErrorMessage(false, null);
    this.setResearchObjectSectionUpdating(true);

    return this.researchObjectsApiService.update(id, data).pipe(
      tap(() => this.researchObjectsApiService.setErrorMessage(null, null)),
      catchError((errorResponse: HttpErrorResponse) => {
        this.researchObjectsApiService.setErrorMessage(errorResponse, sectionName);

        if (errorResponse.status !== 422) {
          return throwError(() => errorResponse);
        }

        return EMPTY;
      }),
      withLatestFrom(this.store.select(selectCategoriesDictionary), this.store.select(selectTemplatesDictionary)),
      map(toResearchObject),
      tap((response: ResearchObject) => {
        this.setEntity(response);
      })
    );
  }

  #fetchNeighborsData(researchObject: ResearchObject): Observable<NeighborsDto> {
    return this.store.select(selectFromRoot).pipe(
      switchMap(fromRoot => {
        const params = this.researchObjectParamsService.getCurrentParams();
        const queryParams = this.developmentItemService.prepareQueryParamsForGetNeighborRequest(
          researchObject.template.category_id,
          params.inInstanceChecked,
          params.sortState
        );

        if (fromRoot) {
          delete queryParams.category_id;
        }

        return this.researchObjectsApiService.getNeighborResearchObjectData(researchObject.id, queryParams);
      })
    );
  }

  #setSectionsIds(previousEntity: ResearchObject, currentEntity: ResearchObject) {
    if (!currentEntity || previousEntity?.id === currentEntity.id) return;

    const sections = [...currentEntity.template.sections].sort(comparePositions);
    const imagesSectionId = sections.find(section => section.type === SectionType.IMAGES)?.id;
    const parameterSectionId = sections.find(section => section.type === SectionType.PARAMETERS)?.id;

    this.setImagesSectionId(imagesSectionId);
    this.setParameterSectionId(parameterSectionId);
  }
}
