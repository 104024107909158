import { AsyncPipe, CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, Inject, input, output, signal, viewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { OperatorFunction, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { selectSelectedProject } from '@app/shared/_root-store/projects-store/projects.selectors';
import { ButtonComponent } from '@shared/_components/button/button.component';
import { SearchSelectComponent } from '@shared/_components/search-select/search-select.component';
import { DevelopmentType, DevelopmentItemLinkedFilesShortDto, RouteParam, DevelopmentItemFileDto, LinkedFileDto } from '@shared/_models';
import { selectRouteParam } from '@shared/_root-store/router-store/selectors';
import { FileApiService } from '@shared/_services/file';

import { GenericModalComponent } from '../../../_components/generic-modal/generic-modal.component';
import { CloseReason } from '../../../_components/generic-modal/utils';
import { IconComponent } from '../../../_components/icon/components/icon/icon.component';
import { IconColors } from '../../../_components/icon/utils/icon-colors';
import { ModalHeaderCenterComponent } from '../../../_components/modal-header-center/modal-header-center.component';
import { MenuAction } from '../../../_components/more-actions/utils';
import { AppSettings } from '../../../_configuration';
import { NavigationService } from '../../../_services/navigation.service';
import { GridElementDevelopmentItemComponent } from '../../grid/grid-element-development-item/grid-element-development-item.component';
import { FileShareEditNameModalComponent } from '../../table/file-share-edit-name-modal/file-share-edit-name-modal.component';
import { FileSharedCountersComponent } from '../file-shared-counters/file-shared-counters.component';

enum FORM_KEYS {
  DEVELOPMENT_ITEMS_DROPDOWN_CONTROL = 'developmentItemsDropdownControl'
}

@Component({
  selector: 'app-file-sharing-development-items',
  standalone: true,
  imports: [
    AsyncPipe,
    GridElementDevelopmentItemComponent,
    SearchSelectComponent,
    ButtonComponent,
    GenericModalComponent,
    ModalHeaderCenterComponent,
    FileSharedCountersComponent,
    FileShareEditNameModalComponent,
    IconComponent,
    CommonModule
  ],
  templateUrl: './file-sharing-development-items.component.html',
  styleUrls: ['./file-sharing-development-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileSharingDevelopmentItemsComponent {
  items = input<DevelopmentItemLinkedFilesShortDto[]>();
  fileId = input<string>();
  fileName = input<string>();
  fileDescription = input<string>();
  developmentItemId = input<string>();
  developmentType = input<DevelopmentType>();
  developmentItemName = input<string>();
  developmentTypeShareNames = input<string[]>();
  storeFile = input<LinkedFileDto>();
  link = output<{ developmentItem: DevelopmentItemLinkedFilesShortDto; shareName: string }>();
  rename = output<{ developmentItem: DevelopmentItemLinkedFilesShortDto; shareName: string }>();
  unlink = output<DevelopmentItemLinkedFilesShortDto>();
  editShare = output<void>();

  unlinkDevelopmentTypeConfirmationModal = viewChild('unlinkDevelopmentTypeConfirmationModal');
  unlinkDevelopmentTypeConfirmationModalRef: NgbModalRef;
  editNameModal = viewChild('editNameModal');
  editNameModalRef: NgbModalRef;
  IconColors = IconColors;

  shareNames = computed<string[]>(() => this.items().map(item => item.files.find(file => file.file_id === this.fileId()).name));
  projectId = toSignal(this.store.select(selectRouteParam(RouteParam.PROJECT_ID)));
  workspaceId = toSignal(this.store.select(selectRouteParam(RouteParam.WORKSPACE_ID)));
  categoryIdFromUrl = toSignal(this.store.select(selectRouteParam(RouteParam.CATEGORY_ID)));
  showDevelopmentItemSearch = signal<boolean>(false);
  developmentItemsDropdown = new FormGroup({
    [FORM_KEYS.DEVELOPMENT_ITEMS_DROPDOWN_CONTROL]: new FormControl(null)
  });
  selectedProject$ = this.store.select(selectSelectedProject);
  selectedDevelopmentItem = signal<DevelopmentItemLinkedFilesShortDto>(null);
  selectedDevelopmentItemShareNames = signal<string[]>(null);
  updateShareName = signal<boolean>(null);
  checkForInitialError = signal<boolean>(true);
  readonly FORM_KEYS = FORM_KEYS;

  developmentItemActions: MenuAction<DevelopmentItemLinkedFilesShortDto>[] = [
    {
      callback: developmentItem => {
        this.updateShareName.set(true);
        this.checkForInitialError.set(false);
        this.openEditNameModal(developmentItem);
      },
      label: 'Edit share name',
      icon: { fileName: 'pen_icon.svg', color: IconColors.grey, hoverColor: IconColors.blue, width: '0.7rem', height: '0.7rem' }
    },
    null,
    {
      callback: developmentItem => this.goToDevelopmentItemFiles(developmentItem),
      label: 'Open share location',
      icon: { fileName: 'open_location_icon.svg', color: IconColors.grey, hoverColor: IconColors.blue, width: '0.7rem', height: '0.7rem' }
    },
    null,
    {
      callback: developmentItem => {
        this.openUnlinkDevelopmentTypeConfirmationModal();
        this.selectedDevelopmentItem.set(developmentItem);
      },
      label: 'Delete share',
      icon: { fileName: 'trash_3_icon.svg', color: IconColors.grey, hoverColor: IconColors.blue, width: '0.7rem', height: '0.8rem' }
    }
  ];

  constructor(
    @Inject(AppSettings) public readonly settings: AppSettings,
    private readonly fileApiService: FileApiService,
    private readonly ngbModalService: NgbModal,
    private readonly navigationService: NavigationService,
    private readonly store: Store
  ) {}

  openUnlinkDevelopmentTypeConfirmationModal() {
    this.unlinkDevelopmentTypeConfirmationModalRef = this.ngbModalService.open(
      this.unlinkDevelopmentTypeConfirmationModal(),
      this.settings.MODAL_DEFAULT_CONFIG
    );
  }

  openEditNameModal(developmentItem: DevelopmentItemLinkedFilesShortDto) {
    this.selectedDevelopmentItem.set(developmentItem);
    this.selectedDevelopmentItemShareNames.set(developmentItem.files.map(file => file.name));
    this.editNameModalRef = this.ngbModalService.open(this.editNameModal(), this.settings.MODAL_DEFAULT_CONFIG);
  }

  close(reason: CloseReason) {
    if (reason === CloseReason.ACCEPT) {
      this.onUnlink(this.selectedDevelopmentItem());
    }

    this.unlinkDevelopmentTypeConfirmationModalRef.close();
  }

  onEditNameModalClose(modal: NgbModalRef) {
    this.showDevelopmentItemSearch.set(false);
    this.selectedDevelopmentItem.set(null);
    modal.close();
  }

  searchDevelopmentItems: OperatorFunction<string, readonly DevelopmentItemLinkedFilesShortDto[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(searchForName =>
        this.#searchDevelopmentItems$(searchForName || null).pipe(
          map(response => {
            return response?.length ? response : [];
          })
        )
      )
    );

  onLink(developmentItem: DevelopmentItemLinkedFilesShortDto): void {
    if (!developmentItem) return;

    this.developmentItemsDropdown.controls[FORM_KEYS.DEVELOPMENT_ITEMS_DROPDOWN_CONTROL].reset();

    const duplicateShareName = developmentItem.files.some(file => file.name === this.fileName());

    this.checkForInitialError.set(true);
    duplicateShareName ? this.openEditNameModal(developmentItem) : this.link.emit({ developmentItem, shareName: this.fileName() });
  }

  onUnlink(developmentItem: DevelopmentItemLinkedFilesShortDto) {
    this.unlink.emit(developmentItem);
  }

  goToDevelopmentItemFiles(developmentItem: DevelopmentItemLinkedFilesShortDto) {
    if (developmentItem.development_type === DevelopmentType.experiment) {
      this.navigationService.goToExperimentFiles(this.workspaceId(), this.projectId(), developmentItem.id, this.categoryIdFromUrl(), true);
    } else {
      this.navigationService.goToResearchObjectFiles(
        this.workspaceId(),
        this.projectId(),
        developmentItem.id,
        this.categoryIdFromUrl(),
        true
      );
    }
  }

  getShareName(developmentItem: DevelopmentItemLinkedFilesShortDto): string {
    const file = developmentItem?.files.find(file => file.file_id === this.fileId());
    return file ? file.name : this.fileName();
  }

  onEditName(fileShare: DevelopmentItemFileDto) {
    const developmentItem = this.selectedDevelopmentItem();
    this.updateShareName()
      ? this.rename.emit({ developmentItem, shareName: fileShare.name })
      : this.link.emit({ developmentItem, shareName: fileShare.name });

    this.updateShareName.set(null);
    this.selectedDevelopmentItem.set(null);
  }

  #searchDevelopmentItems$(search: string): Observable<DevelopmentItemLinkedFilesShortDto[]> {
    return this.fileApiService.getNoneSharedDevelopmentItems(this.projectId(), this.fileId(), { search }).pipe(map(response => response));
  }
}
