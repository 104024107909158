import { CommonModule, NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, EventEmitter, input, Input, OnInit, Output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { map, merge, Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';

import { toMessage } from '@app/domain/error-handling';
import { InputWithClearComponent } from '@app/shared/_components';
import { ButtonComponent } from '@app/shared/_components/button/button.component';
import { GenericModalComponent } from '@app/shared/_components/generic-modal/generic-modal.component';
import { ModalHeaderLeftComponent } from '@app/shared/_components/modal-header-left/modal-header-left.component';
import { DevelopmentType, ProjectDto } from '@app/shared/_models';
import { ExperimentsApiService } from '@app/shared/_services/experiment';
import { ResearchObjectsApiService } from '@app/shared/_services/research-object';
import { CloseReason } from '@shared/_components/generic-modal/utils';

import { InputWithValidationComponent } from '../../../inputs/input-with-validation/input-with-validation.component';

@Component({
  selector: 'app-item-details-edit-name-modal',
  standalone: true,
  templateUrl: './item-details-edit-name-modal.component.html',
  styleUrls: ['./item-details-edit-name-modal.component.scss'],
  imports: [
    InputWithClearComponent,
    ButtonComponent,
    NgClass,
    ReactiveFormsModule,
    NgIf,
    GenericModalComponent,
    ModalHeaderLeftComponent,
    NgTemplateOutlet,
    InputWithValidationComponent,
    CommonModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemDetailsEditNameModalComponent implements OnInit {
  project = input.required<ProjectDto>();
  @Output() closeModal = new EventEmitter<null>();
  @Output() saveBtnClick = new EventEmitter<string>();
  @Input() developmentType: DevelopmentType = null;
  @Input() itemDetailsName: string;

  readonly DevelopmentType = DevelopmentType;
  readonly errorMessage$: Observable<string> = merge(
    this.researchObjectsApiService.errorMessageSection$,
    this.experimentsApiService.errorMessageSection$
  ).pipe(
    map(errorMessage => {
      if (!errorMessage?.errorResponse) {
        return null;
      }
      return toMessage(<HttpErrorResponse>errorMessage?.errorResponse);
    }),
    startWith(null)
  );
  form: FormGroup;
  hasError = toSignal(this.errorMessage$);

  constructor(
    readonly researchObjectsApiService: ResearchObjectsApiService,
    readonly experimentsApiService: ExperimentsApiService
  ) {
    this.createFormControls();
  }

  ngOnInit() {
    this.#setInitialValue();
  }

  #setInitialValue() {
    this.form.get('name').setValue(this.itemDetailsName || null);
  }

  close(reason: CloseReason) {
    if (reason === CloseReason.CLOSE) {
      this.closeModal.emit();
    } else if (reason === CloseReason.ACCEPT) {
      this.saveBtnClick.emit(this.form.value);
      // timeout needed to wait for the error messages to be updated
      setTimeout(() => {
        if (!this.hasError()) {
          this.closeModal.emit();
        }
      }, 100);
    }
  }

  private createFormControls() {
    this.form = new FormGroup({
      name: new FormControl(null)
    });
  }

  onClearName() {
    this.form.get('name').setValue('');
  }
}
