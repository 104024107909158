import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { omitBy, isNil } from 'lodash-es';
import { EMPTY, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

import { WorkspaceService } from '@app/_workspaces/workspace/workspace.service';
import { addErrorToForm } from '@app/domain/error-handling';
import { AppSettings } from '@app/shared/_configuration';
import { ProjectsStoreService } from '@app/shared/_root-store/projects-store/projects-store.service';
import { UsersStoreService } from '@app/shared/_root-store/users-store/users-store.service';
import { ProjectsApiService } from '@app/shared/_services/project';
import { CloseReason } from '@shared/_components/generic-modal/utils';
import { IconColors } from '@shared/_components/icon/utils/icon-colors';
import {
  ProjectDto,
  UpdateProjectDto,
  CreateProjectDto,
  ProjectProxy,
  DevelopmentType,
  DropdownItem,
  ActiveStatus,
  EditMode,
  UserDto
} from '@shared/_models';
import { ModalType } from '@shared/_modules/project/utils';

export enum ACTION_TYPE {
  LEAVE = 'leave',
  JOIN = 'join',
  DELETE = 'delete'
}

@Component({
  selector: 'app-project-edit-add',
  templateUrl: './project-edit-add.component.html',
  styleUrls: ['./project-edit-add.component.scss']
})
export class ProjectEditAddComponent implements OnInit, OnDestroy {
  @Input() modalType: ModalType;
  @Input() selectedProject: ProjectDto;
  @Output() closeModal = new EventEmitter<void>();
  @ViewChild('deleteConfirmationModal', { static: false }) deleteConfirmationModal: ElementRef;

  readonly destroy$: Subject<boolean> = new Subject<boolean>();
  readonly ModalType = ModalType;
  readonly ACTION_TYPE = ACTION_TYPE;
  readonly DevelopmentType = DevelopmentType;
  form: FormGroup;
  statusSelectItems: DropdownItem[] = [
    { id: 1, name: ActiveStatus.ACTIVE },
    { id: 2, name: ActiveStatus.INACTIVE }
  ];
  projectProxy: ProjectProxy;
  IconColors = IconColors;
  currentUser$ = this.usersStoreService.currentUser$;

  get markDescriptionAsInvalid(): boolean {
    const control = this.controls.description;

    return control?.invalid && control?.touched;
  }

  constructor(
    @Inject(AppSettings) public readonly settings: AppSettings,
    public readonly activeModal: NgbActiveModal,
    private readonly projectsApiService: ProjectsApiService,
    private readonly projectsStoreService: ProjectsStoreService,
    private readonly ngbModal: NgbModal,
    private readonly workspaceService: WorkspaceService,
    private readonly usersStoreService: UsersStoreService
  ) {}

  ngOnInit() {
    this.currentUser$.pipe(takeUntil(this.destroy$)).subscribe(currentUser => {
      this.projectProxy = new ProjectProxy(currentUser, this.selectedProject);
      this.initForm(currentUser);
    });
  }

  get controls() {
    return this.form.controls;
  }

  private initForm(currentUser: UserDto) {
    this.form = new FormGroup({
      name: new FormControl(this.selectedProject?.name, Validators.required),
      status: new FormControl<string>(this.selectedProject?.status || 'active'),
      color: new FormControl<number>(this.selectedProject?.color || 0),
      description: new FormControl<string>(this.selectedProject?.description, Validators.maxLength(120)),
      owners: new FormControl<UserDto[]>([this.selectedProject?.user_crt || currentUser], [Validators.required, Validators.maxLength(1)])
    });
  }

  close(reason: CloseReason) {
    if (reason === CloseReason.CLOSE) {
      this.closeModal.emit();
    } else if (reason === CloseReason.ACCEPT) {
      this.onSaveProject();
    }
  }

  onColorChange(color: number) {
    this.controls.color.setValue(color);
  }

  onSaveProject() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.saveChanges();
  }

  openDeleteModal() {
    this.ngbModal.open(this.deleteConfirmationModal, this.settings.MODAL_DEFAULT_CONFIG);
  }

  onDeleteConfirmation(deleteConfirmation: NgbModalRef) {
    this.projectsApiService
      .delete(this.selectedProject.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.projectsStoreService.loadProjects(this.workspaceService.currentWorkspaceId());
        deleteConfirmation.close();
        this.closeModal.emit();
      });
  }

  private saveChanges() {
    const mode = this.modalType === ModalType.NEW ? EditMode.ADD : EditMode.EDIT;
    const addOrUpdate =
      mode === EditMode.ADD
        ? this.projectsApiService.add(this.getProject(mode) as CreateProjectDto)
        : this.projectsApiService.update(this.selectedProject.id, this.getProject(mode));

    addOrUpdate
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.projectsStoreService.loadProjects(this.workspaceService.currentWorkspaceId());
          this.closeModal.emit();
        }),
        catchError(errorResponse => {
          addErrorToForm(errorResponse, this.form);

          return EMPTY;
        })
      )
      .subscribe();
  }

  private getProject(mode: EditMode): Partial<UpdateProjectDto> | CreateProjectDto {
    const project =
      mode === EditMode.ADD
        ? {
            workspace_id: this.workspaceService.currentWorkspaceId(),
            name: this.controls.name.value,
            description: this.controls.description.value,
            status: this.controls.status.value,
            color: this.controls.color.value,
            user_created: this.controls.owners.value.map(owner => owner.id)[0]
          }
        : {
            name: this.controls.name.value,
            description: this.controls.description.value,
            status: this.controls.status.value,
            color: this.controls.color.value,
            user_created: this.controls.owners.value.map(owner => owner.id)[0]
          };

    return omitBy(project, isNil);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
