import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { Component, computed, input, output, signal, OnInit, ChangeDetectionStrategy, Inject, viewChild } from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { provideComponentStore } from '@ngrx/component-store';
import { EMPTY } from 'rxjs';

import { getShortGUID } from '@shared/_helpers';
import { DevelopmentType, LinkedFileDto, DevelopmentItemLinkedFilesShortDto, DevelopmentItem, FileUpdateDto } from '@shared/_models';
import { FileApiService } from '@shared/_services/file';

import { FileSharingModalComponentStore } from './file-sharing-modal.store';
import { GenericModalComponent } from '../../../_components/generic-modal/generic-modal.component';
import { CloseReason } from '../../../_components/generic-modal/utils';
import { ModalHeaderCenterComponent } from '../../../_components/modal-header-center/modal-header-center.component';
import { ModalHeaderLeftComponent } from '../../../_components/modal-header-left/modal-header-left.component';
import { AppSettings } from '../../../_configuration';
import { ProjectsStoreService } from '../../../_root-store/projects-store/projects-store.service';
import { FileShareEditNameModalComponent } from '../../table/file-share-edit-name-modal/file-share-edit-name-modal.component';
import { FileSharingDevelopmentItemsComponent } from '../file-sharing-development-items/file-sharing-development-items.component';
import { FileUploadCardComponent } from '../file-upload-card/file-upload-card.component';
import { FileUploadStatus } from '../models/file-upload-status';

// sliding from right file sharing "modal"
@Component({
  selector: 'app-file-sharing-modal',
  standalone: true,
  imports: [
    GenericModalComponent,
    ModalHeaderLeftComponent,
    NgTemplateOutlet,
    CommonModule,
    FileUploadCardComponent,
    FileSharingDevelopmentItemsComponent,
    ModalHeaderCenterComponent,
    FileShareEditNameModalComponent
  ],
  templateUrl: './file-sharing-modal.component.html',
  styleUrls: ['./file-sharing-modal.component.scss'],
  providers: [provideComponentStore(FileSharingModalComponentStore)],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileSharingModalComponent implements OnInit {
  file = input<LinkedFileDto>();
  developmentItem = input<DevelopmentItem>();
  closeModal = output<boolean>();
  loadFiles = output<void>();
  onLastUnlinked = output<void>();

  selectedProject = this.projectsService.selectedProject;
  storeFile$ = this.componentStore.file$;
  storeFileSignal = toSignal(this.storeFile$);
  linkedToDevelopmentItems$ = this.componentStore.linkedToDevelopmentItems$;
  file$ = toObservable(this.file);
  fileUploadStatus = signal<FileUploadStatus>({
    guid: null,
    fileToUpload: null,
    uploadPending: false,
    uploadHttpEvents$: EMPTY
  });
  fileName = computed<string>(() => this.fileUploadStatus().fileToUpload?.name ?? this.file().name);
  fileSize = computed<number>(() => this.fileUploadStatus().fileToUpload?.size ?? this.file().size);
  fileExtension = computed<string>(() => this.fileUploadStatus().fileToUpload?.type ?? this.file().type);
  fileExperimentsCount = computed<number>(() => this.storeFileSignal()?.count_experiments ?? 0);
  fileResearchObjectsCount = computed<number>(() => this.storeFileSignal()?.count_research_objects ?? 0);
  developmentType = computed<DevelopmentType>(() => this.developmentItem()?.template.category.development_type);
  developmentTypeShareNames = computed<string[]>(() => this.developmentItem()?.files.map(f => f.name));

  DevelopmentType = DevelopmentType;
  developmentItems: DevelopmentItemLinkedFilesShortDto[];
  developmentItemsSearchValues: DevelopmentItemLinkedFilesShortDto[] = [];

  deleteSourceFileConfirmationModal = viewChild('deleteSourceFileConfirmationModal');
  editNameModal = viewChild('editNameModal');

  constructor(
    @Inject(AppSettings) private readonly settings: AppSettings,
    private readonly fileApiService: FileApiService,
    private readonly componentStore: FileSharingModalComponentStore,
    private readonly ngbModalService: NgbModal,
    private readonly projectsService: ProjectsStoreService
  ) {
    this.#syncStore();
  }

  ngOnInit() {
    this.componentStore.setup(this.file());
    this.componentStore.loadLinkedToDevelopmentItems();
  }

  onFileSelected(file: File) {
    const updatedStatus = this.#createFileUploadStatusFromFile(file);
    this.fileUploadStatus.update(value => ({
      ...value,
      ...updatedStatus
    }));
  }

  onUploadInProgress() {
    this.fileUploadStatus.update(value => ({
      ...value,
      uploadPending: true
    }));
  }

  onUploadSuccess() {
    this.fileUploadStatus.update(value => ({
      ...value,
      uploadPending: false
    }));
    this.loadFiles.emit();
  }

  onUploadFail() {
    this.fileUploadStatus.update(value => ({
      ...value,
      uploadPending: false
    }));
  }

  onLink(developmentItem: DevelopmentItemLinkedFilesShortDto, shareName: string) {
    this.componentStore.link({ developmentItem: developmentItem, shareName });
    if (this.developmentItem().id === developmentItem.id) {
      this.loadFiles.emit();
    }
  }

  onUnlink(developmentItem: DevelopmentItemLinkedFilesShortDto) {
    this.componentStore.unlink({
      developmentItem: developmentItem,
      onSuccess: () => {
        if (this.developmentItem().id === developmentItem.id) {
          this.closeModal.emit(true);
        }
      }
    });
  }

  onRename(developmentItem: DevelopmentItemLinkedFilesShortDto, shareName: string) {
    this.componentStore.updateShareName({ developmentItem, shareName });
    if (this.developmentItem().id === developmentItem.id) {
      this.loadFiles.emit();
    }
  }

  openConfirmationModal() {
    this.ngbModalService.open(this.deleteSourceFileConfirmationModal(), this.settings.MODAL_DEFAULT_CONFIG);
  }

  openEditNameModal() {
    this.ngbModalService.open(this.editNameModal(), this.settings.MODAL_DEFAULT_CONFIG);
  }

  onCloseDeleteFileSourceConfirmation(reason: CloseReason, modal: NgbModalRef) {
    if (reason === CloseReason.CLOSE) {
      modal.close();
      return;
    }

    // ACCEPT
    this.onDelete();
  }

  onDelete() {
    this.componentStore.deleteFile({
      file: this.file(),
      onSuccess: () => {
        this.onLastUnlinked.emit(), this.ngbModalService.dismissAll();
      }
    });
  }

  onEditName(data: FileUpdateDto) {
    const fileUpdate: Partial<FileUpdateDto> = {
      ...(this.file().name !== data.name ? { name: data.name } : {}),
      ...(this.file().description !== data.description ? { description: data.description } : {})
    };

    if (Object.keys(fileUpdate).length > 0) {
      const updatedData = {
        fileId: this.file().id,
        fileUpdate
      };
      this.componentStore.updateFile(updatedData);
    }
  }

  #createFileUploadStatusFromFile(file: File): FileUploadStatus {
    const formData = new FormData();
    formData.append('file', file);

    const uploadHttpEvents$ = this.fileApiService.replace(formData, this.file().id);
    return {
      guid: getShortGUID(),
      fileToUpload: file,
      uploadPending: false,
      uploadHttpEvents$
    };
  }

  #syncStore() {
    this.file$.pipe(takeUntilDestroyed()).subscribe(file => {
      this.componentStore.setup(file);
    });
  }
}
