<div
  [routerLink]="['../', itemData().template.category_id, itemData().id]"
  [queryParams]="{ fromRoot: (currentCategoryId$ | async) === '-1' }"
  [class.items-card--selected]="isResearchObjectSelected(itemData().id) | async"
  class="items-card research-object"
  data-testid="ro-card-item">
  <div class="items-card__checkbox">
    <div class="form-check">
      <input
        (change)="toggleResearchObjectSelection($event, itemData().id)"
        [checked]="isResearchObjectSelected(itemData().id) | async"
        (click)="$event.stopPropagation()"
        data-testid="ro-card-item-checkbox"
        class="form-check-input"
        type="checkbox" />
      <!-- TODO: Apply Pipe: itemData.id | isDevelopmentItemSelected:researchObjectsSelectedIds$|async -->
    </div>
  </div>
  <div
    class="items-card__oid items-card__oid--{{ DevelopmentType.researchObject }} items-card__oid--size-{{ firstSectionSpace() }}"
    data-testid="ro-card-item-oid">
    {{ itemData().oid }}
  </div>
  <!-- 1) Image x3 | more -->
  <div *ngIf="!hideImageArea()" class="items-card__segment items-card__segment--image" [class]="imageSpaceClass()">
    <div class="items-card__segment__placeholder">
      <app-grid-item-image
        [developmentType]="DevelopmentType.researchObject"
        [developmentItem]="itemData()"
        [categoryName]="itemData().template.category.name"
        (metaChanged)="onImageMetaChanged($event)"></app-grid-item-image>
    </div>
  </div>
  <!-- 2) Name & Category x1 | x2 | more -->
  <header class="items-card__segment items-card__segment--main" [class]="nameAndCategorySpaceClass()">
    <div class="items-card__segment__content w-100">
      <div
        [ngbTooltip]="itemData().name"
        [openDelay]="300"
        appOverflowContentPluginToNgbTooltip
        defaultTooltipValue="Name"
        class="items-card__segment__name w-100"
        data-testid="ro-name">
        {{ itemData().name }}
      </div>
      <div
        *ngIf="currentCategoryHasAnyChild()"
        [openDelay]="300"
        data-testid="ro-card-item-category-name"
        ngbTooltip="Category"
        class="items-card__segment__category-name">
        {{ itemData().template.category.name }}
      </div>
    </div>
  </header>
  <!-- 3) User x1 | Params 3x -->
  <div
    class="items-card__segment"
    *ngIf="currentCategoryHasAnyChild() || !hideParametersArea()"
    [class]="userOrParamsSpaceClass()"
    data-testid="userOrParams">
    @if (currentCategoryHasAnyChild()) {
      <div class="items-card__segment__content items-card__segment__content--vertical">
        <app-user-avatar [imageBase64]="avatar$ | async" avatarSize="0.875rem"></app-user-avatar>
        <span ngbTooltip="Added by">{{ itemData().user_crt.name }} {{ itemData().user_crt.surname }}</span>
      </div>
    } @else if (parametersRows().length) {
      <div class="items-card__segment-params">
        <div *ngFor="let paramsRow of parametersRows()" class="items-card__segment-params__row">
          <div
            *ngFor="let param of paramsRow"
            class="items-card__segment-params__row__param"
            [attr.data-test]="'paramFieldDoc_' + param.name">
            <span class="items-card__segment-params__row__param__value">
              @if (param.value) {
                <app-field-value-preview [field]="param" [minimized]="true"></app-field-value-preview>
              } @else {
                <span>-</span>
              }
            </span>
            <span class="items-card__segment-params__row__param__name" [attr.data-test]="'paramFieldName_' + param.name">
              {{ param.name || '-' }}
            </span>
          </div>
        </div>
      </div>
    } @else {
      <div class="items-card__segment__content">-</div>
    }
  </div>
  <!-- 4) Samples 1x | 2x -->
  <div *ngIf="!hideSamplesArea()" class="items-card__segment" [class]="samplesSpaceClass()" data-testid="samples">
    <app-items-counter
      [items]="instancesNumbers"
      [textBeforeCounter]="firstInstanceNumber"
      [tooltipHeader]="samplesAreaName()"
      [defaultTooltipValue]="instanceSectionName()"
      container="body"
      class="items-card__segment__instances"></app-items-counter>
  </div>
  <!-- 5) Footer 1x -->
  <footer class="items-card__segment items-card__segment--footer" [class.items-card__segment--footer-centered]="!showExperiments()">
    <span ngbTooltip="Date Added" [openDelay]="300">
      {{ itemData().date_created | date: 'dd.MM.YYYY' }}
    </span>
    <span
      *ngIf="showExperiments() && selectedProject()?.development_type_names.experiments"
      [ngbTooltip]="'Used in ' + selectedProject()?.development_type_names.experiments"
      [openDelay]="300"
      container="body"
      data-testid="related-ex-count"
    >
      {{ itemData().experiments?.length || '0' }} {{ experimentsName() | slice: 0 : 3 | uppercase }}
    </span>
  </footer>
</div>
