import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { BehaviorSubject, Observable, shareReplay } from 'rxjs';

import { toReducedParams } from '@shared/_helpers';
import { toParams } from '@shared/_helpers/pagination';
import {
  ExperimentDto,
  ExperimentAddDto,
  ExperimentUpdateDto,
  NeighborsDto,
  Pageable,
  PagedResponse,
  Searchable,
  ErrorMessageSection,
  UpdateArea,
  Sortable,
  ListIdsDto,
  DevelopmentItemUpdateFileDto,
  DevelopmentItemFileDto,
  DevelopmentItemUpdateImageDto,
  ImageDto
} from '@shared/_models';

import { EXPERIMENTS_API_URL, EXPERIMENTS_CSV_EXPORT_API_URL, EXPERIMENTS_CSV_MULTIPLE_EXPORT_API_URL } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class ExperimentsApiService {
  constructor(private readonly http: HttpClient) {}

  errorMessageSection$ = new BehaviorSubject<ErrorMessageSection>({
    errorResponse: false,
    section: null
  });

  getList(params?: Searchable & Pageable & Sortable) {
    return this.http
      .get<PagedResponse<ExperimentDto>>(`${EXPERIMENTS_API_URL}`, {
        params: toParams(params)
      })
      .pipe(shareReplay());
  }

  get(experimentId: string): Observable<ExperimentDto> {
    return this.http.get<ExperimentDto>(`${EXPERIMENTS_API_URL}/${experimentId}`);
  }

  add(experiment: ExperimentAddDto): Observable<ExperimentDto> {
    return this.http.post<ExperimentDto>(`${EXPERIMENTS_API_URL}`, experiment);
  }

  update(experimentId: string, changes: Partial<ExperimentUpdateDto>, params?: { [param: string]: string }): Observable<ExperimentDto> {
    return this.http.patch<ExperimentDto>(`${EXPERIMENTS_API_URL}/${experimentId}`, changes, toReducedParams(params));
  }

  setErrorMessage(errorResponse: HttpErrorResponse | boolean, section: UpdateArea): void {
    this.errorMessageSection$.next({ errorResponse, section });
  }

  delete(experimentId: string): Observable<string> {
    return this.http.delete<string>(`${EXPERIMENTS_API_URL}/${experimentId}`);
  }

  deleteMultiple(ids: string[]) {
    return this.http.post<any>(`${EXPERIMENTS_API_URL}/batch_delete`, ids);
  }

  generateCSV(experiment_ids: string[]): Observable<Blob> {
    return this.http.post<Blob>(`${EXPERIMENTS_CSV_EXPORT_API_URL}`, { experiment_ids }, { responseType: 'blob' as 'json' });
  }

  generateMultipleCSV(exp_ids_grouped_by_category: string[][]): Observable<Blob> {
    return this.http.post<Blob>(
      `${EXPERIMENTS_CSV_MULTIPLE_EXPORT_API_URL}`,
      { exp_ids_grouped_by_category },
      { responseType: 'blob' as 'json' }
    );
  }

  getNeighborExperimentData(experimentId: string, params: Params) {
    return this.http.get<NeighborsDto>(`${EXPERIMENTS_API_URL}/${experimentId}/neighbors`, {
      params
    });
  }

  getListOfIds(params: Params = {}) {
    return this.http.get<ListIdsDto>(`${EXPERIMENTS_API_URL}/list_ids`, { params }).pipe(shareReplay());
  }

  addShare(experimentId: string, share: DevelopmentItemUpdateFileDto) {
    return this.http.post<DevelopmentItemFileDto>(`${EXPERIMENTS_API_URL}/${experimentId}/add_share`, share);
  }

  updateShare(experimentId: string, share: DevelopmentItemUpdateFileDto) {
    return this.http.patch<DevelopmentItemFileDto>(`${EXPERIMENTS_API_URL}/${experimentId}/update_share/${share.file_id}`, {
      name: share.name
    });
  }

  removeShares(experimentId: string, shareIds: string[]) {
    return this.http.post<void>(`${EXPERIMENTS_API_URL}/${experimentId}/remove_shares`, shareIds);
  }

  addImage(researchObjectId: string, payload: DevelopmentItemUpdateImageDto, isMain?: boolean) {
    const params: Params = isMain ? { main_image: 'true' } : {};

    return this.http.post<ImageDto>(`${EXPERIMENTS_API_URL}/${researchObjectId}/add_image`, payload, { params });
  }

  removeImages(researchObjectId: string, imageIds: string[]) {
    return this.http.post<void>(`${EXPERIMENTS_API_URL}/${researchObjectId}/remove_images`, imageIds);
  }
}
