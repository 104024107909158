<div data-testid="file-card" class="d-flex image-card">
  <app-image-thumb class="me-2" [gradientBorder]="isMain()" [developmentType]="developmentType" [image]="image"></app-image-thumb>
  <div class="file-card__content-wrapper">
    <div class="d-flex flex-column overflow-hidden flex-fill">
      <div class="image-card__top-container">
        <span *ngIf="isMain()" class="image-card__main-image-label" [ngClass]="developmentType | developmentTypeCSSClass">main image</span>
      </div>
      <div class="overflow-ellipsis image-card__image-name">{{ image?.name }}</div>
      <div class="d-flex justify-content-between image-card__meta-data-container">
        <div class="image-card__image-size">{{ image?.size | formatFileSize }}</div>
        <div class="image-card__image-type">{{ image?.type | mimeSubtype }}</div>
      </div>
    </div>
    <app-more-actions [actions]="actions()" buttonLabel="ACTIONS" class="image-card__actions"></app-more-actions>
  </div>
</div>
