import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';

import { toParams } from '@app/shared/_helpers';
import {
  FileDto,
  LinkedFileDto,
  FileUploadParams,
  DevelopmentType,
  DevelopmentItemLinkedFilesDto,
  PagedResponse,
  DeleteMultipleResponseDto,
  Searchable,
  DevelopmentItemLinkedFilesShortDto,
  FileUpdateDto
} from '@shared/_models';
import { FILES_API_URL } from '@shared/_services/constants';

@Injectable({
  providedIn: 'root'
})
export class FileApiService {
  constructor(private http: HttpClient) {}

  getList(params: Params) {
    return this.http.get<PagedResponse<LinkedFileDto>>(`${FILES_API_URL}`, { params });
  }

  getSharedDevelopmentItems(fileId: string): Observable<DevelopmentItemLinkedFilesShortDto[]> {
    return this.http.get<DevelopmentItemLinkedFilesShortDto[]>(`${FILES_API_URL}/${fileId}/linked`);
  }

  getNoneSharedDevelopmentItems(
    projectId: string,
    fileId: string,
    params: Searchable = {}
  ): Observable<DevelopmentItemLinkedFilesShortDto[]> {
    return this.http.get<DevelopmentItemLinkedFilesShortDto[]>(`${FILES_API_URL}/${projectId}/${fileId}/unlinked`, {
      params: toParams(params)
    });
  }

  get(fileId: string): Observable<Blob> {
    const url = `${FILES_API_URL}/${fileId}/content`;

    return this.http.get(url, { responseType: 'blob' });
  }

  getMultiple(fileIds: string[], object_id: string, development_type: DevelopmentType): Observable<Blob> {
    const params: Params = { object_id, development_type };

    return this.http.post(`${FILES_API_URL}/download_many`, fileIds, { responseType: 'blob', params });
  }

  upload(formData: FormData, params: FileUploadParams): Observable<HttpEvent<FileDto>> {
    return this.http.post<FileDto>(`${FILES_API_URL}`, formData, {
      reportProgress: true,
      observe: 'events',
      params
    });
  }

  replace(formData: FormData, fileId: string): Observable<HttpEvent<FileDto>> {
    return this.http.post<FileDto>(`${FILES_API_URL}/${fileId}/replace`, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  delete(fileId: string): Observable<void> {
    return this.http.delete<void>(`${FILES_API_URL}/${fileId}`);
  }

  deleteMultiple(fileIds: string[]): Observable<DeleteMultipleResponseDto> {
    return this.http.post<DeleteMultipleResponseDto>(`${FILES_API_URL}/batch_delete`, fileIds);
  }

  getLinkedFiles(
    projectId: string,
    developmentType: DevelopmentType,
    params?: Params
  ): Observable<PagedResponse<DevelopmentItemLinkedFilesDto>> {
    return this.http.get<PagedResponse<DevelopmentItemLinkedFilesDto>>(`${FILES_API_URL}/${projectId}/${developmentType}`, { params });
  }

  update(fileId: string, data: Partial<FileUpdateDto>): Observable<FileDto> {
    return this.http.patch<FileDto>(`${FILES_API_URL}/${fileId}`, data);
  }
}
