// Storage Keys
export const STORAGE_TOKEN_KEY = 'access_token';

// Random string generation
export const RANDOM_STRING_GENERATOR_DEFAULT_LENGTH = 40;
export const MIN_RANDOM_STRING_LENGTH = 15;

// Other
export const SECOND_MS = 1000;
export const CONTACT_ADMINISTRATOR = 'marcin.feder@adamed.com';
