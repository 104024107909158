<app-generic-modal [hideFooter]="true" [hideHeaderCloseIcon]="true">
  <ng-template #headerTemplate>
    <ng-template [ngTemplateOutlet]="modalHeaderTemplate"></ng-template>
  </ng-template>
  <div class="file-sharing-modal-header-container">
    <div class="file-sharing-modal-header-container__label">Source file</div>
    <button class="file-sharing-modal-header-container__button" (click)="openConfirmationModal()">
      <div class="delete-share-icon"></div>
      DELETE
    </button>
  </div>
  <div class="sharing-modal-upload-container">
    <app-file-upload-card
      [fileSharing]="true"
      [fileUploadInProgress]="fileUploadStatus().uploadPending"
      [fileName]="storeFileSignal().name"
      [fileSize]="fileSize()"
      [fileExtension]="fileExtension()"
      [uploadEvents$]="fileUploadStatus().uploadHttpEvents$"
      (uploadSuccess)="onUploadSuccess()"
      (uploadFail)="onUploadFail()"
      (uploadInProgress)="onUploadInProgress()"
      (fileSelected)="onFileSelected($event)"
      (delete)="openConfirmationModal()"
      (edit)="openEditNameModal()"
    ></app-file-upload-card>
  </div>
  <app-file-sharing-development-items
    [items]="linkedToDevelopmentItems$ | async"
    [developmentItemId]="developmentItem().id"
    [developmentType]="developmentType()"
    [developmentItemName]="developmentItem().name"
    [developmentTypeShareNames]="developmentTypeShareNames()"
    [fileId]="this.file().id"
    [fileName]="this.file().name"
    [fileDescription]="this.file().description"
    [storeFile]="storeFile$ | async"
    (link)="onLink($event.developmentItem, $event.shareName)"
    (unlink)="onUnlink($event)"
    (rename)="onRename($event.developmentItem, $event.shareName)"
    (editShare)="openEditNameModal()"
  ></app-file-sharing-development-items>
</app-generic-modal>

<ng-template #modalHeaderTemplate>
  <app-modal-header-left
    header="Manage"
    itemName="File and shares"
    imgSrc="share_icon_big.svg"
    [categoryName]="file().name"
    [developmentType]="DevelopmentType.all"
    (closeModal)="closeModal.emit(true)"
  ></app-modal-header-left>
</ng-template>

<ng-template #deleteSourceFileConfirmationModal let-modal>
  <app-generic-modal
    [closeButton]="{ text: 'No, keep it' }"
    [acceptButton]="{ text: 'Yes, delete it' }"
    [developmentType]="developmentType()"
    [primary]="true"
    [hideHeaderCloseIcon]="true"
    (closeModal)="onCloseDeleteFileSourceConfirmation($event, modal)"
  >
    <ng-template #headerTemplate>
      <app-modal-header-center
        [imgSrc]="'toast_icon_warning.png'"
        [header]="'Are you sure you want to delete this source file?'"
      ></app-modal-header-center>
    </ng-template>
    <div class="info-modal__body">
      <span class="info-modal__body-content">
        Deleting the
        <span class="content-highlight">{{ this.file().name }}</span>
        source file will
        <span class="content-highlight">delete the source file and all shares from file shares view.</span>
        File is shared with
        <span class="content-highlight">{{ fileExperimentsCount() }}</span>
        {{ selectedProject()?.development_type_names.experiments }} and
        <span class="content-highlight">{{ fileResearchObjectsCount() }}</span>
        {{ selectedProject()?.development_type_names.research_objects }}.
      </span>
    </div>
  </app-generic-modal>
</ng-template>

<ng-template #editNameModal let-modal>
  <app-file-share-edit-name-modal
    [fileName]="file().name"
    [fileDescription]="file().description"
    [developmentType]="developmentType()"
    [developmentItemName]="developmentItem().name"
    [sourceFile]="true"
    (closeModal)="modal.close()"
    (saveBtnClick)="onEditName($event)"
  ></app-file-share-edit-name-modal>
</ng-template>
