import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  EventEmitter,
  input,
  Output,
  OnInit,
  viewChild,
  inject,
  DestroyRef
} from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { provideComponentStore } from '@ngrx/component-store';
import { filter, tap } from 'rxjs/operators';

import { ProjectsStoreService } from '@app/shared/_root-store/projects-store/projects-store.service';
import { CloseReason } from '@shared/_components/generic-modal/utils';
import { ModalHeaderLeftComponent } from '@shared/_components/modal-header-left/modal-header-left.component';
import { DevelopmentItem, DevelopmentItemFile, DevelopmentItemFileDto, DevelopmentType, FileDto, ImageDto } from '@shared/_models';
import { FileUploadLimitAcceptedExtensionsPipe } from '@shared/_modules/file/pipes/file-upload-limit-accepted-extensions.pipe';

import { FileCategory } from '../models/file-category';
import { FileUploadStatus } from '../models/file-upload-status';
import { FilesUploadShareInfoComponent } from './components/files-upload-share-info/files-upload-share-info.component';
import { UploadCardsComponent } from './components/upload-cards/upload-cards.component';
import { ShareNameData } from './components/upload-cards/utils';
import { FileUploadModalComponentStore } from './file-upload.store';
import { keepCreatedSharesOnTopOfTheList, toDevelopmentItemFileDto } from './utils';
import { ToastService } from '../../toast/toast.service';

@Component({
  selector: 'app-files-upload',
  standalone: true,
  imports: [
    CommonModule,
    FileUploadLimitAcceptedExtensionsPipe,
    FilesUploadShareInfoComponent,
    UploadCardsComponent,
    ModalHeaderLeftComponent,
    NgbTooltip
  ],
  templateUrl: './files-upload.component.html',
  styleUrls: ['./files-upload.component.scss'],
  providers: [provideComponentStore(FileUploadModalComponentStore)],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilesUploadComponent implements OnInit {
  fileInputElement = viewChild<ElementRef>('fileInputElement');

  fileCategory = input.required<FileCategory>();
  developmentItem = input.required<DevelopmentItem>();
  sectionName = input<string>();
  // we manage updating RO/EX heigher, this flag is about those operations
  pending = input<boolean>(false);
  shares = input<FileUploadStatus[]>();

  @Output() closed = new EventEmitter<FileDto[]>();
  @Output() showListOfAllImages = new EventEmitter<{ anyFileUpdated: boolean }>();
  @Output() uploadSuccess = new EventEmitter<DevelopmentItemFile>();
  @Output() deleteSuccess = new EventEmitter<string>();
  @Output() shareFiles = new EventEmitter<void>();

  shares$ = toObservable(this.shares);
  selectedProject = this.projectsService.selectedProject;
  developmentType = computed<DevelopmentType>(() => this.developmentItem().template.category.development_type);
  header = computed<{ top: string; middle: string; bottom: string }>(() => {
    if (this.fileCategory() === FileCategory.files)
      return {
        top: 'Add files and',
        middle: 'Create shares in',
        bottom: this.developmentItem().name
      };

    // FileCategory.images
    return {
      top: this.developmentItem().name,
      middle: 'Adding images to',
      bottom: this.sectionName()
    };
  });
  headerIcon = computed<string>(() => (this.fileCategory() === FileCategory.files ? 'file_shares_icon.svg' : 'upload_image_icon.svg'));
  fileUploadStatuses = toSignal<FileUploadStatus[]>(this.componentStore.data$);
  anyUploadPending = computed<boolean>(() => this.fileUploadStatuses().some(status => status.uploadPending) || this.pending());
  rootClass = computed<string>(() =>
    [
      'lg-files-modal files-upload',
      this.fileCategory() === FileCategory.files ? 'lg-files-modal--files' : 'lg-files-modal--images',
      this.fileUploadStatuses().length ? 'lg-files-modal--added' : null
    ]
      .filter(Boolean)
      .join(' ')
  );
  files = computed<DevelopmentItemFileDto[]>(() =>
    this.fileCategory() === FileCategory.files ? this.developmentItem().files : this.developmentItem().images.map(toDevelopmentItemFileDto)
  );
  uploadingPanelHeader = computed<string>(() =>
    this.fileCategory() === FileCategory.files ? 'Uploaded files and created shares' : 'Uploaded images'
  );
  #creatingShare: (fileUploadStatus: FileUploadStatus) => void;
  #destroyRef = inject(DestroyRef);

  readonly DevelopmentType = DevelopmentType;
  readonly FileCategory = FileCategory;
  readonly CloseReason = CloseReason;

  constructor(
    private readonly projectsService: ProjectsStoreService,
    private readonly componentStore: FileUploadModalComponentStore,
    private readonly toastService: ToastService
  ) {
    this.#handleSharesChange();
    this.#keepCreatedSharesOnTopOfTheList();
  }

  ngOnInit(): void {
    this.componentStore.setup(this.fileCategory(), this.developmentItem);
  }

  onFileSelected($event: Event): void {
    const files = Array.from(($event.target as HTMLInputElement).files);

    if (files.length === 0) return;

    if (files.length > 10) {
      this.toastService.show('You cannot upload more than 10 files at once. Please try again.', {
        header: 'Too many files selected',
        type: 'danger'
      });
      this.resetFileInput();
      return;
    }

    // <input type="file"> change event is not triggered when the same file is selected again
    this.resetFileInput();
    this.componentStore.onSelectedFiles(files);
  }

  dropHandler($event: DragEvent): void {
    this.handleDragEvent($event);
    this.componentStore.onDropedFiles($event.dataTransfer.items);
  }

  handleDragEvent($event: DragEvent): void {
    $event.stopPropagation();
    $event.preventDefault();
  }

  onUploadCancel(guid: string): void {
    this.componentStore.cancelUpload(guid);
  }

  onUploadInProgress(guid: string): void {
    this.componentStore.uploadStarted(guid);
  }

  onUploadSuccess(event: { dto: FileDto | ImageDto; guid: string; initiallyValid: boolean }): void {
    this.componentStore.uploadSucceeded({ ...event, onValidated: file => this.uploadSuccess.emit(file) });
  }

  onUploadFail(guid: string) {
    this.componentStore.uploadFailed(guid);
  }

  onFileRemove({ fileId, guid }): void {
    this.componentStore.removeFile({
      fileId,
      guid,
      onSuccess: () => {
        this.deleteSuccess.emit(fileId);
        this.resetFileInput();
      }
    });
  }

  resetFileInput(): void {
    if (!this.fileInputElement()?.nativeElement) return;

    this.fileInputElement().nativeElement.value = '';
  }

  onShareNameChanged(shareNameChange: ShareNameData): void {
    this.componentStore.shareNameChanged(shareNameChange);
  }

  onCreate(guid: string): void {
    const status = this.fileUploadStatuses().find(status => status.guid === guid);
    const { dto, name } = status;
    this.#creatingShare(status);
    this.uploadSuccess.emit({ file: dto, name });
  }

  onClose() {
    this.closed.emit(
      this.fileUploadStatuses()
        .filter(status => !!status.dto)
        .map(status => status.dto)
    );
  }

  #handleSharesChange() {
    this.shares$
      .pipe(
        takeUntilDestroyed(),
        filter(shares => !!shares?.length),
        tap(shares => this.componentStore.onSharesAdded(shares))
      )
      .subscribe();
  }

  #keepCreatedSharesOnTopOfTheList() {
    const { creatingShare } = keepCreatedSharesOnTopOfTheList(
      this.files,
      this.fileUploadStatuses,
      statuses => this.componentStore.setData(statuses),
      this.#destroyRef
    );
    this.#creatingShare = creatingShare;
  }
}
