import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, forkJoin, from } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { UserDto } from '@app/shared/_models';
import { blobToBase64, NEGLIGIBLE_BLOB_SIZE } from '@shared/_helpers/blob-to-base64';
import { selectRouteParam } from '@shared/_root-store/router-store/selectors';

import * as actions from './users.actions';
import { getAvatarIdsToLoad } from './users.reducer';
import { selectUsersState } from './users.selectors';
import { UserApiService } from '../../_services/user';

@Injectable()
export class UsersEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly userApiService: UserApiService,
    private store: Store,
    private router: Router
  ) {}

  loadCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadCurrentUser),
      concatMap(() =>
        this.userApiService.getCurrentUser().pipe(
          map(user => actions.loadCurrentUserSuccess({ user })),
          catchError(error => of(actions.loadCurrentUserFailure({ error })))
        )
      )
    )
  );

  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadUser),
      concatMap(({ id }) =>
        this.userApiService.getUser(id).pipe(
          map(user => actions.loadUserSuccess({ user })),
          catchError(error => of(actions.loadUserFailure({ error })))
        )
      )
    )
  );

  loadCurrentUserFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.loadCurrentUserFailure),
        tap(error => {
          if (error.error.status === 500) {
            this.router.navigateByUrl('app/error');
            return;
          }
        })
      );
    },
    { dispatch: false }
  );

  loadUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadUserSuccess),
      map(({ user }) => {
        const { id } = user;

        return actions.loadAvatars({ ids: [id] });
      })
    )
  );

  loadUsersWithAvatars$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadUsersWithAvatars),
      withLatestFrom(this.store.select(selectRouteParam('workspaceId'))),
      switchMap(([_, workspace_id]) => this.userApiService.getUsers({ workspace_id })),
      map((users: UserDto[]) => actions.loadUsersWithAvatarsSuccess({ users })),
      catchError(error => of(actions.loadUsersWithAvatarsFailure({ error })))
    )
  );

  loadUsersWithAvatarsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadUsersWithAvatarsSuccess),
      map(({ users }) => {
        const ids = users.map(({ id }) => id);

        return actions.loadAvatars({ ids });
      })
    )
  );

  loadAvatars$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadAvatars),
      withLatestFrom(this.store.select(selectUsersState)),
      map(([{ ids }, state]) => {
        // Filter out already loaded avatars
        const idsToLoad = getAvatarIdsToLoad(ids, state);

        return actions.loadAvatarsStart({ ids: idsToLoad });
      })
    )
  );

  loadAvatarsStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadAvatarsStart),
      filter(({ ids }) => ids.length > 0),
      switchMap(({ ids }) => {
        return forkJoin(
          ids.map(id =>
            this.userApiService.getUserImage(id).pipe(
              map(blob => ({ id, blob })),
              catchError(() => of({ id, blob: null }))
            )
          )
        ).pipe(
          switchMap((blobs: { id: string; blob: Blob }[]) =>
            forkJoin(
              blobs.map(({ id, blob }) => {
                if (!blob || blob.size < NEGLIGIBLE_BLOB_SIZE) return of({ id, base64: null });

                return from(blobToBase64(blob)).pipe(map(base64 => ({ id, base64 })));
              })
            )
          ),
          map(avatars => actions.loadAvatarsSuccess({ avatars })),
          catchError(() => of(actions.loadAvatarsFailure({ ids })))
        );
      })
    )
  );
}
